import { useTranslate } from "../../../helpers/i18n/useTranslate";
import React, { useEffect, useState } from "react";
import StepByStepFacebookLoginModal from "../../../modules/core/profile/components/settings/modals/StepByStepFacebookLoginModal";
import { useAuth } from "../../../modules/auth";
import { useApi } from "../../../helpers/Api/ApiWrapper";
import { toast } from "react-toastify";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAlert } from "../../../helpers/utils/CustomAlert";
import Swal from "sweetalert2/src/sweetalert2";
import "/node_modules/@sweetalert2/theme-dark/dark.scss";
import "../../../helpers/utils/CustomAlert.css";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

/**
 * Inicializa o SDK do Facebook
 * @returns {Promise<unknown>}
 */
export default function initFacebookSDK() {
    return new Promise((resolve) => {
        // Wait for the Facebook SDK to initialize before starting the React app.
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: FACEBOOK_APP_ID,
                cookie: true,
                xfbml: true,
                version: "v19.0",
            });

            resolve();
        };

        // Load the Facebook SDK script.
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    });
}

function ensureFacebookSDKReady() {
    return new Promise((resolve, reject) => {
        if (typeof FB !== "undefined") {
            resolve();
        } else {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: FACEBOOK_APP_ID,
                    cookie: true,
                    xfbml: true,
                    version: "v19.0",
                });
                resolve();
            };

            (function (d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            })(document, "script", "facebook-jssdk");
        }
    });
}

/**
 * Componente que renderiza um botão de conectar com a conta Spotify da pessoa e faz o redirect pro endpoint do Spotify
 * que abre a janelinha de oauth
 * @returns {JSX.Element}
 * @constructor
 */
const MetaLoginButton = () => {
    const { POST, PUT } = useApi();
    const { alert } = useAlert();
    const { currentUser, setCurrentUser, currentArtist } = useAuth();
    const { t } = useTranslate();
    const [facebookUserAccessToken, setFacebookUserAccessToken] = useState(
        currentArtist?.facebook_long_lived_token
    );
    const [isFacebookLoginModalOpen, setIsFacebookLoginModalOpen] =
        useState(false);
    const [useSDKLogout, setUseSDKLogout] = useState(false);
    const toggleFacebookLoginModal = () =>
        setIsFacebookLoginModalOpen(!isFacebookLoginModalOpen);
    const [loading, setLoading] = useState(false);

    // Inicializa o SDK do Facebook ao carregar a página
    useEffect(() => {
        initFacebookSDK();
    }, []);

    useEffect(() => {
        let token = currentArtist?.facebook_long_lived_token;
        setFacebookUserAccessToken(token);
    }, [currentArtist?.facebook_long_lived_token]);

    /**
     * Obtém um token de acesso de curta duração do usuário no Facebook
     */
    const logInToFB = () => {
        setLoading(true);
        window.FB.login(
            (response) => {
                UpdateBackEndFacebookLongLivedUserAccessToken(
                    response.authResponse?.accessToken
                );
                setUseSDKLogout(true);
            },
            {
                // Escopos para publicar conteúdo no Instagram e gerenciar anúncios
                // scope: "email,read_insights,pages_show_list,ads_management,ads_read,business_management,instagram_basic,instagram_manage_insights,pages_read_engagement,pages_manage_ads",
                scope: "email,public_profile,pages_show_list,ads_management,business_management,instagram_basic,instagram_manage_insights,pages_read_engagement",
            }
        );
        toggleFacebookLoginModal();
    };

    /**
     * Desconecta a conta do Facebook, limpando o token na sessão e no BD
     * @returns {Promise<void>}
     */
    const logOutOfFB = async () => {
        // setLoading(true);
        try {
            if (!currentArtist?.id) throw new Error("No artist id");
            let url = `core/artist-facebook-data/${currentArtist?.id}`;
            let formData = new FormData();
            formData.append("id", currentArtist?.id);
            formData.append("facebook_long_lived_token", "");
            await PUT(url, formData);
            if (useSDKLogout) {
                window.FB.logout(() => {
                    setFacebookUserAccessToken("");
                });
            } else {
                setFacebookUserAccessToken("");
            }
            // Mensagem de sucesso e redirecionamento de volta pro app
            alert.fire(
                "Seu perfil no Instagram foi desconectado do AmplifyUs!"
            );
            // .then(() => window.location.replace("/back-to-app"));
        } catch (e) {
            console.error(e);
            toast.error(t("GENERAL.ACTION-FAILED"));
        } finally {
            // setLoading(false);
        }
        setCurrentUser({ ...currentUser, facebookToken: "" });
    };

    /**
     * Obtém o LLT do usuário e guarda no backend
     * @returns {Promise<void>}
     * @constructor
     */
    const UpdateBackEndFacebookLongLivedUserAccessToken = async (token) => {
        if (!currentUser) {
            return;
        }
        setLoading(true);
        if (!token) {
            console.error("Error fetching access token");
            let formData = new FormData();
            formData.append(
                "message",
                `Erro pra pegar o access token do usuário ${currentUser.email}`
            );
            POST("register-log", formData);
            setLoading(false);
            return;
        }
        try {
            var accessToken = await getLongLivedUserAccessToken(token);
        } catch (error) {
            console.error("Error fetching long-lived token:", error);
            let formData = new FormData();
            formData.append(
                "message",
                `Erro pra pegar o token do usuário ${currentUser.email} e colocar no artista ${currentArtist.id}: ${error}`
            );
            POST("register-log", formData);
            setLoading(false);
            return;
        }
        try {
            if (!accessToken) {
                throw new Error("No access token");
            }
            if (!currentArtist?.id) throw new Error("No artist id");
            let url = `core/artist-facebook-data/${currentArtist?.id}`;
            let formData = new FormData();
            formData.append("id", currentArtist?.id);
            formData.append("facebook_long_lived_token", accessToken);
            await PUT(url, formData);
            // Mensagem de sucesso e redirecionamento de volta pro app
            // setLoading(false);
            setFacebookUserAccessToken(accessToken);
            setLoading(false);
            alert
                .fire("Seu perfil no Instagram foi conectado ao AmplifyUs!")
                .then(() => window.location.replace("/back-to-app"));
        } catch (e) {
            setFacebookUserAccessToken("");
            // setLoading(false);
            console.error(e);
            if (e.response && e.response.status === 400) {
                let data = e.response.data;

                Swal.fire({
                    title: `<strong>${data.error_title}</strong>`,
                    icon: "error",
                    html: `
                    <strong>${data.error_subtitle}</strong>
                    <br>
                    <br>
                    <ul>
                        ${data.error_solutions
                            .map((error) => `<li>○ ${error}</li><br>`)
                            .join("")}
                    </ul>
                  `,
                    showCloseButton: false,
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: `
                    Ok
                  `,
                    cancelButtonText: `
                    Preciso de ajuda!
                  `,
                }).then((result) => {
                    if (!result.isConfirmed) {
                        window.open(
                            "https://api.whatsapp.com/send?phone=5531975756731&text=Ol%C3%A1!%20Sou%20usu%C3%A1rio%20do%20AmplifyUs%20e%20estou%20tendo%20dificuldade%20para%20conectar%20minha%20conta%20do%20Instagram"
                        );
                    }
                });
            } else {
                toast.error(t("GENERAL.ACTION-FAILED"));
            }
            setLoading(false);
        }
    };

    /**
     * Obtém um LLT para o usuário no Facebook
     * @returns {Promise<unknown>}
     */
    const getLongLivedUserAccessToken = async (token) => {
        try {
            await ensureFacebookSDKReady();
            const response = await new Promise((resolve, reject) => {
                window.FB.api(
                    "oauth/access_token",
                    {
                        client_id: FACEBOOK_APP_ID,
                        client_secret:
                            process.env.REACT_APP_FACEBOOK_CLIENT_SECRET,
                        grant_type: "fb_exchange_token",
                        fb_exchange_token: token,
                    },
                    (response) => {
                        if (response && !response.error) {
                            resolve(response.access_token);
                        } else {
                            reject(
                                new Error(
                                    response.error
                                        ? response.error.message
                                        : "Unknown error occurred"
                                )
                            );
                        }
                    }
                );
            });

            return response; // Retorna o token de acesso de longa duração
        } catch (error) {
            let formData = new FormData();
            formData.append(
                "message",
                `Erro pra pegar o token do usuário ${currentUser.email} e colocar no artista ${currentArtist.id}: ${error}`
            );
            POST("register-log", formData);
            console.error("Error fetching long-lived token:", error);
            throw error; // Propaga o erro para que possa ser tratado por quem chamar a função
        }
    };

    return (
        <>
            <main id="app-main">
                <section className="app-section">
                    {facebookUserAccessToken ? (
                        <button
                            onClick={logOutOfFB}
                            disabled={loading}
                            className="btn btn-sm btn-light btn-active-light-danger text-slate-50 w-full p-4"
                        >
                            {t(
                                "APPS.CORE.ACCOUNT.CONNECTED-ACCOUNTS.DISCONNECT"
                            )}
                        </button>
                    ) : (
                        <button
                            style={{
                                backgroundColor: "#E1306C",
                            }}
                            className="rounded-md text-slate-50 text-lg font-semibold p-4 w-full"
                            onClick={toggleFacebookLoginModal}
                            disabled={loading}
                        >
                            <FontAwesomeIcon
                                icon={faInstagram}
                                size="lg"
                                className="me-2"
                            />
                            {t("AUTH.ACCOUNTS.INSTAGRAM.LOGIN")}
                        </button>
                    )}
                </section>
            </main>
            <StepByStepFacebookLoginModal
                isOpen={isFacebookLoginModalOpen}
                toggle={toggleFacebookLoginModal}
                loginFacebook={logInToFB}
            />
        </>
    );
};
export { MetaLoginButton };
