import React from "react";
import AppDownloadButtons from "../../components/AppDownloadButtons";
import { GoogleTagManagerScript } from "../../components/GoogleTagManagerScript";

const DownloadAppPage = () => {
    return (
        <>
            {/*<GoogleTagManagerScript*/}
            {/*    tagId={process.env.REACT_APP_GOOGLE_TAG_MANAGER_DEFAULT_TAG_ID}*/}
            {/*/>*/}
            <div
                className="flex flex-col justify-center items-center h-screen overflow-hidden bg-cover bg-center"
                style={{
                    backgroundImage:
                        "url('/media/logos/tumblr_0c9ebfb8020aabf870853808ca1a376d_81bc6d24_500.gif')",
                }}
            >
                <div
                    className="flex flex-col justify-center items-center p-8 rounded-lg"
                    style={{
                        background:
                            "radial-gradient(circle, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0) 100%)",
                    }}
                >
                    <img
                        src="/media/logos/default-dark.svg"
                        alt="AmplifyUs Logo"
                        style={{ height: "80px", width: "auto" }}
                    />
                    <div className="flex flex-col text-center gap-5 my-5">
                        <span className="text-3xl text-white">Parabéns!</span>
                        <span className="text-2xl text-white">
                            Sua conta está pronta. Baixe agora o AmplifyUs app
                            para acessar!
                        </span>
                    </div>
                    <AppDownloadButtons />
                </div>
            </div>
        </>
    );
};

export { DownloadAppPage };
