import { Helmet } from "react-helmet-async";
import React from "react";

const GoogleTagManagerScript = ({ tagId }) => {
    return (
        <>
            <Helmet>
                {/* Adiciona o script do Google Tag Manager */}
                <script>
                    {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${tagId}');
                    `}
                </script>
            </Helmet>
            {/* Adiciona a tag noscript diretamente no corpo */}
            <input
                type={"text"}
                value={`https://www.googletagmanager.com/ns.html?id=${tagId}`}
            ></input>
            <noscript>
                <iframe
                    src={`https://www.googletagmanager.com/ns.html?id=${tagId}`}
                    height="0"
                    width="0"
                    style={{ display: "none", visibility: "hidden" }}
                    title="Google Tag Manager"
                />
            </noscript>
        </>
    );
};

export { GoogleTagManagerScript };
